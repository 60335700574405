@-ms-viewport {
    width: device-width;
}

/*
  0 - 600px:      Phone
  600 - 900px:    Tablet portrait
  900 - 1200px:   Tablet landscape
  [1200 - 1800] is where the normal styles apply
  1800px + :      Big desktop
  
  $breakpoint argument choices:
  - phone
  - tab-port (tablet portrait)
  - tab-land (tablet landscape)
  - big-desktop
  
  ORDER: Base + typography > general layout + grid > page layout > components
  */
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
    font-size: 100%;
}

html {
    font-size: 62.5%;
    width: 100%;
    height: 100vh;
}

body {
    font-family: 'Calibri', sans-serif !important;
    font-weight: 400;
    line-height: 1.7;
    color: #ffffff !important;
    box-sizing: border-box;
    background-color: black !important;
    width: 100%;
    height: 100%;
}

#root,
.App {
    height: 100%;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
    html {
        height: -webkit-fill-available;
    }
    body {
        height: -webkit-fill-available;
        max-height: 100vh;
    }
    #root,
    .App {
        height: -webkit-fill-available;
        max-height: 100%;
    }
}

@-moz-document url-prefix() {
    html {
        min-height: -moz-available;
        height: 100vh;
    }
    body {
        min-height: -moz-available;
        height: 100%;
    }
    #root,
    .App {
        min-height: -moz-available;
        height: 95%;
    }
    .calendarContainer {
        min-height: 90%;
    }
}

.header {
    height: 10%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header_icon {
    width: 4rem;
    margin-right: 1.5rem;
    filter: #ffcc33;
}
.header_title {
    display: inline-block;
    color: #ffcc33;
}

.login_container {
    height: 90vh;
}

.login_text {
    color: #ffcc33;
}

.login_buttonContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 65%;
    margin-top: 1rem;
}

.login_pinButton {
    margin: 1.5rem;
    background-color: #ffcc33 !important;
    border: none !important;
    border-radius: 100px !important;
    font-size: 3.5rem !important;
    padding: 0 1.7rem !important;
    color: black !important;
}
@media only screen and (min-width: 56.25em) {
    .login_pinButton {
        margin: 3rem !important;
    }
}
@media only screen and (min-width: 75em) {
    .login_pinButton {
        margin: 3.5rem !important;
    }
}

.login_star--item {
    background-color: black !important;
    color: #ffcc33 !important;
    font-weight: bold;
}

.login_star--container {
    margin-top: 4rem;
}

.failedModal {
    background-color: black !important;
}
@media only screen and (max-width: 37.5em) {
    .failedModal_modal {
        max-width: 70% !important;
        margin: 0 auto !important;
    }
}
.failedModal_modal .modal-content {
    background-color: black !important;
}
.failedModal_header {
    display: block !important;
    border-top-left-radius: calc(2rem - 1px) !important;
    border-top-right-radius: calc(2rem - 1px) !important;
}
.failedModal h5 {
    display: block !important;
    text-align: center !important;
    color: #ffcc33 !important;
    font-size: 2rem !important;
}
.failedModal_closeButton {
    display: none;
}
.failedModal_footer {
    border-bottom-left-radius: calc(2rem - 1px) !important;
    border-bottom-right-radius: calc(2rem - 1px) !important;
}
.failedModal_button {
    color: black !important;
    background-color: #ffcc33 !important;
    border-color: black !important;
    border-radius: 0.5rem !important;
    font-size: 2rem !important;
}

.modal-backdrop {
    background-color: white !important;
}

.modal-content {
    border-radius: 2rem !important;
}

.forbidden_text {
    font-size: 2.2rem;
    color: #ffcc33;
    text-align: center;
    margin: 1.5rem;
}

.calendar_text {
    color: #ffcc33;
    font-size: 2.2rem;
    text-align: center;
    margin: 0;
}

.spinner {
    width: 40px;
    height: 40px;
    margin: 100px auto;
    background-color: #333;
    border-radius: 100%;
    -webkit-animation: sk-scaleout 1s infinite ease-in-out;
    animation: sk-scaleout 1s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        opacity: 0;
    }
}

@keyframes sk-scaleout {
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 0;
    }
}

.fc-next-button,
.fc-prev-button {
    background-color: #ffcc33 !important;
    color: black !important;
    width: 4rem;
    height: 4rem;
    border-radius: 100px !important;
    margin: 1rem;
    border-color: black !important;
}
.fc-next-button span,
.fc-prev-button span {
    font-size: 2.2rem;
}

.fc-today-button {
    background-color: #ffcc33 !important;
    color: black !important;
    border-color: black !important;
}

.fc-footer-toolbar {
    justify-content: center !important;
    margin-top: 0 !important;
}

.fc-bg-event {
    opacity: 0.8 !important;
    border: 2px solid #ffcc33 !important;
}

.fc-day-today {
    background-color: #ffcc33 !important;
}

.eventTitle {
    margin-top: 1.5rem;
    text-align: center;
}

.calendarComponent {
    height: 100%;
}
.calendarComponent_buttonGroup {
    display: flex !important;
}
.calendarComponent_stat--container {
    display: inline-block !important;
    margin-left: auto;
}
.calendarComponent_stat--button {
    background-color: #ffcc33 !important;
    color: black !important;
    border-color: transparent !important;
    margin-right: 0.1rem;
    margin-left: 0.1rem;
}
.calendarComponent_stat--button:hover {
    background-color: #ffbf00 !important;
}

.loginStats_modalHeader {
    display: block !important;
    border-top-left-radius: calc(2rem - 1px) !important;
    border-top-right-radius: calc(2rem - 1px) !important;
    background-color: #ffcc33 !important;
    border-bottom: 1px solid #ffcc33 !important;
    color: black !important;
}
.loginStats_modalHeader h5 {
    display: block !important;
    text-align: center !important;
    color: black !important;
    font-size: 2rem !important;
}

.loginStats_text {
    color: #ffcc33;
}

.loginStats_listItem {
    background-color: black !important;
    color: #ffcc33 !important;
}

.calendarContainer {
    height: 86%;
    display: flex;
    flex-direction: column;
}
.calendarContainer_reservedSpan {
    font-size: 1.5rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.calendarContainer_reservedBadge {
    background-color: #dd6227 !important;
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.4rem;
}

.mainContent {
    height: 90%;
    display: flex;
    flex-direction: column;
}

.menuFooter {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 12%;
    min-height: 6rem;
    background-color: black;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 0.5rem;
}
.menuFooter_menuButton {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    background: none;
    border: none;
    cursor: pointer;
}
.menuFooter_menuButton:focus {
    outline: 1px dotted #ffcc33;
}
.menuFooter_menuButton:hover svg,
.menuFooter_menuButton:active svg {
    transform: scale(1.1);
    color: #ffcc33;
}
.menuFooter_menuButton::-moz-focus-inner {
    border: 0;
}
.menuFooter_menuButton svg {
    outline: none;
    transition: transform 0.15s linear;
}
.menuFooter_menuButton--active svg {
    transform: scale(1.1);
    color: #ffcc33;
}
.menuFooter_menuIcon {
    color: #ffffff;
    font-size: 4rem;
}

.bottomGap {
    height: 12%;
    min-height: 6rem;
}

.houseInfoContainer {
    margin-bottom: 2rem;
}

.userHouseInfo--row {
    justify-content: center;
    margin: 0 !important;
}

.userHouseInfo--col {
    display: flex;
    flex-direction: column-reverse !important;
    justify-content: center;
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.userHouseInfo--col:last-of-type {
    border-bottom: 1px solid #ffcc33;
}

.userHouseInfo--questionButton {
    background-color: black !important;
    color: #ffcc33 !important;
    border-color: #ffcc33 !important;
    border-bottom: none !important;
    border-radius: 0 !important;
    width: 100% !important;
    text-align: start !important;
}

.userHouseInfo--questionSpan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.6rem;
}
@media only screen and (min-width: 37.5em) {
    .userHouseInfo--questionSpan {
        font-size: 1.9rem;
    }
}

.userHouseInfo--questionIcon {
    transition: all 0.3s ease-in-out;
}

.userHouseInfo--collapse {
    border: 1px solid #ffcc33;
    border-top: none;
    border-bottom: none;
}

.userHouseInfo--card {
    background-color: black !important;
    font-size: 1.5rem;
}
@media only screen and (min-width: 37.5em) {
    .userHouseInfo--card {
        font-size: 1.8rem;
    }
}

.userHouseInfo--collapse.collapse.show ~ .userHouseInfo--questionButton .userHouseInfo--questionSpan i,
.userHouseInfo--collapse.collapsing ~ .userHouseInfo--questionButton .userHouseInfo--questionSpan i {
    transform: rotate(90deg) !important;
}

.adminHouseInfo--form {
    padding: 1rem;
    background-color: #ffcc33;
    color: black;
}

.adminHouseInfo--formButton {
    border-color: black !important;
}

.adminHouseInfo--cardHeader {
    display: flex !important;
    justify-content: flex-end;
}
.adminHouseInfo--cardHeader button {
    font-size: 1.6rem !important;
}

.places--container {
    margin-right: 2rem;
    margin-left: 2rem;
}

.places--navContainer {
    border-bottom: 0.5px solid #ffcc33 !important;
    cursor: pointer;
    justify-content: space-evenly;
}
.places--navContainer .nav-item {
    flex-grow: 1;
    justify-content: center;
}
.places--navContainer .nav-link {
    font-size: 16px;
    color: #ffcc33 !important;
    flex-grow: 1;
    justify-content: center;
}
.places--navContainer .nav-link:hover {
    border-color: #ffcc33 !important;
}
.places--navContainer .nav-link.active {
    background-color: #ffcc33 !important;
    color: black !important;
    border-color: #ffcc33 !important;
}

.places--navTitleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
.places--navTitleContainer .customIcon {
    height: 1.2em;
    width: 1.2em;
    margin-right: 0.5rem;
}
@media only screen and (max-width: 1010px) and (min-width: 990px) {
    .places--navTitleContainer .customIcon {
        height: 1.1em;
        width: 1.1em;
        margin-right: 0.3rem;
    }
}
@media only screen and (max-width: 37.5em) {
    .places--navTitleContainer .customIcon {
        height: 1.5em;
        width: 1.5em;
        margin-right: 0;
    }
}

@media only screen and (max-width: 37.5em) {
    .places--navTitleSpan {
        display: none;
    }
}

.places--tabContents {
    padding-top: 1rem !important;
}

.places--tabContentTitle {
    display: none;
}
@media only screen and (max-width: 37.5em) {
    .places--tabContentTitle {
        display: block;
        text-align: center;
        color: #ffcc33;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }
}

.places--editModeCardRow {
    flex: 2;
}

.places--card_header {
    background-color: black !important;
}

.places--card_title {
    color: #dd6227;
    font-size: 2rem;
}

.places--card_subtitle {
    color: #ffcc33;
    font-size: 1.2rem;
    display: flex;
}

.places--card_image {
    max-height: 40rem;
    object-fit: contain;
    object-position: center;
    background-color: black;
}

.places--card_footer {
    background-color: black !important;
    color: #ffcc33 !important;
    height: 100%;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.places--card_footer--buttonGroup {
    display: flex !important;
    justify-content: flex-end;
}
.places--card_footer--buttonGroup button {
    font-size: 1.6rem !important;
}

_::-webkit-full-page-media html,
_:future html,
:root .safari_only html {
    height: 100vh !important;
    height: -webkit-calc(100% - 0px) !important;
}

_::-webkit-full-page-media body,
_:future body,
:root .safari_only body {
    height: 100vh !important;
    height: -webkit-calc(100% - 0px) !important;
}

_::-webkit-full-page-media #root,
_::-webkit-full-page-media .App,
_:future #root,
_:future .App,
:root .safari_only #root,
:root .safari_only .App {
    height: 95vh !important;
}

_::-webkit-full-page-media .mainContent,
_::-webkit-full-page-media .calendarContainer,
_:future .mainContent,
_:future .calendarContainer,
:root .safari_only .mainContent,
:root .safari_only .calendarContainer {
    height: 90vh !important;
}

_::-webkit-full-page-media .calendarComponent,
_:future .calendarComponent,
:root .safari_only .calendarComponent {
    height: 85vh !important;
    position: absolute !important;
}
